<template>
  <div class="contentWrapper">
    <nav-bar />
    <v-card v-if="isLoggedIn" class="userSettingsWrapper">
      <v-tabs v-model="tab" show-arrows color="#137547">
        <v-tab v-for="tab in tabs" :key="tab.icon">
          <v-icon size="20" class="me-3">
            {{ tab.icon }}
          </v-icon>
          <span>{{ tab.title }}</span>
        </v-tab>
      </v-tabs>
      <v-tabs-items class="tabs" v-model="tab" v-if="isLoggedIn">
        <v-tab-item> <MyProducts /> </v-tab-item>
        <v-tab-item> <ChangeInfo /> </v-tab-item>
        <v-tab-item> <ChangePassword /> </v-tab-item>
      </v-tabs-items>
    </v-card>
  </div>
</template>

<script>
import ChangeInfo from "./ChangeInfo.vue";
import ChangePassword from "./ChangePassword.vue";
import MyProducts from "./MyProducts.vue";
import NavBar from "../../Components/NavBar.vue";

export default {
  components: {
    ChangeInfo,
    ChangePassword,
    MyProducts,
    NavBar,
  },
  data() {
    return {
      tab: false,
      tabs: [
        { title: "Oglasi", icon: "mdi-layers" },
        { title: "Profil", icon: "mdi-account" },
        { title: "Promeni lozinku", icon: "mdi-security" },
      ],
    };
  },
  computed: {
    isLoggedIn() {
      return this.$store.getters.isAuthenticated;
    },
    currentLoggedUser() {
      return this.$store.getters.StateUser;
    },
  },
};
</script>

<style lang="scss" scoped>
.contentWrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  margin-bottom: 60px;
  .userSettingsWrapper {
    .tabs {
      min-height: 313px;
    }
    margin: 2rem auto;
    width: 90%;
    max-width: 1440px;
  }
  .dialogWrapper {
    margin-top: 5rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .dialogWindow {
    border-radius: 8px;
    border: 1px solid #ccc;
    background-color: #fff;
  }
}
@media screen and (min-width: 1140px) {
  .contentWrapper {
    margin-bottom: auto;
  }
}
</style>
